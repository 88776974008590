/* Background Container */
.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(135deg, #219ebc, #e0f7fa); /* Dégradé pêche et rose */
    font-family: 'Roboto', sans-serif;
  }
  
  /* Login Card with Glassmorphism Effect */
  .login-card {
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(12px);
    width: 380px;
    padding: 2.5rem;
    border-radius: 12px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
    text-align: center;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
  
  /* Title */
  .login-title {
    font-size: 2rem;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 1.8rem;
   
  }
  
  /* Form Group */
  .form-group {
    margin-bottom: 1.5rem;
    text-align: left;
  }
  
  .form-group label {
    font-size: 0.9rem;
    color: #ffffff;
    font-weight: 500;
    margin-bottom: 0.5rem;
    display: block;
  }
  
  .form-group input {
    width: 100%;
    padding: 0.75rem;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    font-size: 1rem;
    color: #333;
    background: rgba(255, 255, 255, 0.85);
    outline: none;
    transition: all 0.3s ease;
  }
  
  .form-group input:focus {
    border-color: #fa709a; /* Accentuation en rose clair au focus */
    box-shadow: 0 0 6px rgba(255, 182, 193, 0.4);
  }
  
  /* Button */
  .logincompany-button {
    background: linear-gradient(135deg, #219ebc, #176e8c);
    color: white;
    font-size: 1rem;
    padding: 0.75rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.3s ease, background 0.3s ease;
    width: 100%;
  }
  
  .logincompany-button:hover {
    background: linear-gradient(135deg, #176e8c, #219ebc);
    transform: translateY(-2px);
  }
  
  .logincompany-button:active {
    transform: translateY(0);
  }
  
  /* Responsive Design */
  @media (max-width: 480px) {
    .logincompany-card {
      width: 90%;
      padding: 1.5rem;
    }
  
    .logincompany-title {
      font-size: 1.75rem;
    }
  }
  