/* Style global */
body {
  font-family: 'Roboto', sans-serif;
  background-color: #f4f4f9;
  margin: 0;
  padding: 0;
}

.dashboard {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
}

.dashboard-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #1f2937;
  text-align: center;
  margin-bottom: 1rem;
}

/* Section Vue d'Ensemble */
.overview {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 40px;
}

.overview-item {
  background-color: white;
  padding: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 30%;
  border-radius: 1rem;
}

.overview-item h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #219ebc;
}

.overview-item p {
  font-size: 2rem;
  color: #4b5563;
  margin-top: 10px;
}

/* Section Gestion des Plannings */
.planning-section {
  margin-bottom: 40px;
}

.planning-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  font-weight: 600;
  color: #1f2937;
  border-bottom: 2px solid #219ebc;
  padding-bottom: 10px;
}

.shift-form {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 30px;
  background-color: #f8fafc;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #e5e7eb;
}

.shift-form input,
.shift-form button {
  padding: 12px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 250px;
}

.shift-form button {
  background-color: #219ebc;
  color: white;
  cursor: pointer;
  width: auto;
  transition: background-color 0.3s ease, transform 0.2s;
}

.shift-form button:hover {
  background-color: #1b83a3;
  transform: scale(1.05);
}

.planning-list {
  margin-top: 30px;
  padding: 20px;
  background-color: #f8fafc;
  border-radius: 10px;
  border: 1px solid #e5e7eb;
}

.planning-list table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
}

.planning-list th,
.planning-list td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.planning-list th {
  background-color: #219ebc;
  color: white;
  font-weight: bold;
}

.planning-list td {
  color: #333;
}

.planning-list tr:hover {
  background-color: #f1f5f9;
}

/* Section Calendrier */
.calendar-section {
  margin-top: 40px;
  margin-bottom: 40px;
}

.calendar-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  font-weight: 600;
  color: #1f2937;
  border-bottom: 2px solid #219ebc;
  padding-bottom: 10px;
}

.fc {
  border-radius: 1rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  overflow: hidden;
  margin: 0 auto;
  max-height: 500px; /* Réduction de la hauteur du calendrier */
}

/* Section Graphiques */
.charts-section {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  flex-wrap: wrap;
}

.chart-card {
  background-color: white;
  padding: 2rem;
  width: 48%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  margin-bottom: 20px;
}

.chart-card h2 {
  text-align: center;
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 20px;
}

.chart-card canvas {
  width: 100%;
  height: auto;
}

/* Responsivité */
@media (max-width: 768px) {
  .overview {
    flex-direction: column;
    align-items: center;
  }

  .overview-item {
    width: 100%;
    margin-bottom: 20px;
  }

  .shift-form {
    justify-content: center;
  }

  .shift-form input,
  .shift-form button {
    width: 100%;
  }

  .planning-list table {
    font-size: 0.9rem;
  }

  .charts-section {
    flex-direction: column;
  }

  .chart-card {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media (max-width: 600px) {
  .dashboard-title {
    font-size: 1.75rem;
  }

  .overview-item {
    padding: 1rem;
    width: 100%;
  }

  .shift-form input,
  .shift-form button {
    width: 100%;
  }

  .feature-card,
  .pricing-card,
  .chart-card {
    width: 100%;
  }

  .home-logo {
    width: 120px;
    height: 120px;
    margin-top: 1.5rem;
  }

  .btn-primary {
    padding: 0.75rem 1.5rem;
    font-size: 0.9rem;
    border-radius: 0.4rem;
  }

  /* Contact Section */
  .contact {
    padding: 2rem 1rem;
  }

  .contact h2 {
    font-size: 1.75rem;
  }

  .contact p {
    font-size: 1rem;
  }

  footer {
    padding: 1.5rem 0;
  }

  footer p {
    font-size: 0.9rem;
  }
}

/* Toast Success Message */
.success-toast {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #4caf50;
  color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  z-index: 1000;
  transition: opacity 0.3s ease-out;
  opacity: 1;
}

.success-toast.hidden {
  opacity: 0;
  pointer-events: none;
}

/* Filter Form */
.filter-form {
  display: flex;
  gap: 20px;
}

.filter-form input,
.filter-form button {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  width: 200px;
}

.filter-form button {
  background-color: #219ebc;
  color: white;
  cursor: pointer;
  width: 120px;
  transition: background-color 0.3s ease, transform 0.2s;
}

.filter-form button:hover {
  background-color: #1b83a3;
  transform: scale(1.05);
}
