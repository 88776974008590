/* Login Container */
.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #e0f7fa; /* Bleu clair apaisant */
  }
  
  /* Login Card */
  .login-card {
    background-color: white;
    width: 350px;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    text-align: center;
  }
  
  /* Title */
  .login-title {
    font-size: 1.8rem;
    font-weight: 600;
    color: #219ebc; /* Bleu entreprise */
    margin-bottom: 1.5rem;
  }
  
  /* Form Group */
  .form-group {
    margin-bottom: 1.5rem; /* Ajout d'espace entre les champs */
    text-align: left;
  }
  
  .form-group label {
    font-size: 0.9rem;
    color: #333;
    font-weight: 500;
    margin-bottom: 0.5rem;
    display: block;
  }
  
  .form-group input {
    width: 100%;
    padding: 0.75rem;
    border-radius: 6px;
    border: 1px solid #ccc;
    font-size: 1rem;
    color: #333;
    outline: none;
    transition: border-color 0.3s, box-shadow 0.3s;
  }
  
  .form-group input:focus {
    border-color: #219ebc;
    box-shadow: 0 0 5px rgba(33, 158, 188, 0.5);
  }
  
  /* Button */
  .login-button {
    background-color: #219ebc;
    color: white;
    font-size: 1rem;
    padding: 0.75rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    width: 100%;
  }
  
  .login-button:hover {
    background-color: #176e8c;
    transform: translateY(-2px);
  }
  
  .login-button:active {
    transform: translateY(0);
  }
  
  /* Responsive Design */
  @media (max-width: 480px) {
    .login-card {
      width: 90%;
      padding: 1.5rem;
    }
  
    .login-title {
      font-size: 1.5rem;
    }
  }
  