/* Page Home - Styles supplémentaires */

.home-container {
  background-color: white;
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
}

.home-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.home-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #1f2937;
  text-align: center;
  margin-bottom: 1rem;
}

.home-subtitle {
  font-size: 1.2rem;
  font-weight: 400;
  color: #4b5563;
  text-align: center;
}

.features {
  background-color: #219ebc;
  padding-top: 2rem;
  display: flex;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;
}

.feature-list {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.feature-card {
  background-color: white;
  padding: 2rem;
  margin-bottom: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  text-align: center;
  transition: transform 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
}

.feature-card:hover {
  transform: translateY(-10px);
  background-color: #e0f4fb; /* Effet de survol plus clair */
}

.feature-card h3 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 1rem;
}

.feature-card p {
  font-size: 1rem;
  color: #4b5563;
}

.pricing {
  background-color: #f9fafb;
  padding: 4rem 0;
}

.pricing-cards {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.pricing-card {
  background-color: white;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 300px;
  text-align: center;
  transition: transform 0.3s ease;
}

.pricing-card:hover {
  transform: translateY(-10px);
  background-color: #e0f4fb;
}

.pricing-card h3 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.pricing-card p {
  font-size: 1.2rem;
  color: #4b5563;
  margin-bottom: 1rem;
}

.pricing-card ul {
  text-align: left;
  margin-bottom: 1rem;
}

.home-logo {
  height: 200px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.btn-primary {
  background-color: #219ebc;
  color: white;
  border: none;
  padding: 1rem 2rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-primary:hover {
  background-color: #1b83a3; /* Assombrissement de la couleur */
}

/* Contact Section */
.contact {
  background-color: #f9fafb;
  padding: 4rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.contact h2 {
  font-size: 2.5rem;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 1rem;
}

.contact p {
  font-size: 1.2rem;
  color: #4b5563;
  margin-bottom: 2rem;
}

.contact button {
  background-color: #219ebc;
  color: white;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact button:hover {
  background-color: #1b83a3;
}

/* Footer */
footer {
  background-color: #333;
  color: white;
  padding: 2rem 0;
}

footer p {
  margin: 0;
  font-size: 1rem;
  text-align: center;
}

/* Styles responsive */
@media (max-width: 768px) {
  .home-title {
    font-size: 2rem;
  }

  .home-subtitle {
    font-size: 1rem;
  }

  .pricing-cards, .features, .feature-list {
    flex-direction: column;
    gap: 2rem;
    width: 100%;
  }

  .pricing-card, .feature-card {
    width: 100%;
    margin: 0 auto;
  }

  .home-logo {
    width: 150px;
    height: 150px;
  }
}

/* Responsive pour les téléphones */
@media (max-width: 600px) {
  .home-title {
    font-size: 1.75rem;
  }

  .home-subtitle {
    font-size: 0.9rem;
    padding: 0 1rem;
  }

  .features {
    gap: 1rem;
    padding-top: 1rem;
  }

  .feature-card, .pricing-card {
    padding: 1rem;
    width: 90%;
    margin: 0 auto;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  .feature-card h3, .pricing-card h3 {
    font-size: 1.1rem;
  }

  .feature-card p, .pricing-card p {
    font-size: 0.9rem;
  }

  .home-logo {
    width: 120px;
    height: 120px;
    margin-top: 1.5rem;
  }

  .btn-primary {
    padding: 0.75rem 1.5rem;
    font-size: 0.9rem;
    border-radius: 0.4rem;
  }

  /* Contact Section */
  .contact {
    padding: 2rem 1rem;
  }

  .contact h2 {
    font-size: 1.75rem;
  }

  .contact p {
    font-size: 1rem;
  }

  footer {
    padding: 1.5rem 0;
  }

  footer p {
    font-size: 0.9rem;
  }
}
