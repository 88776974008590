/* Container */
.calculator-container {
    width: 100%;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    font-family: 'Roboto', sans-serif;
    color: #333;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.outil{
}

.calculator-container:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}

/* Titles */
h1 {
    font-size: 1.6rem;
    color: #222f3e;
    font-weight: 700;
    margin-bottom: 1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
}

h2 {
    font-size: 1rem;
    color: #576574;
    margin-bottom: 1.5rem;
    font-weight: 500;
}

/* Option Buttons */
.option-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2rem;
}

.option-button {
    background-color: #1e3799;
    color: white;
    border: none;
    padding: 0.7rem 1.5rem;
    font-size: 0.9rem;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    width: 40%;
}

.option-button:hover {
    background-color: #3c6382;
    transform: translateY(-3px);
}

/* Time Inputs */
.time-input {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
    margin-top: 4rem;
    font-size: 0.9rem;
    width: 100%;
}

.time-input label {
    width: 180px;
    text-align: right;
    font-weight: 600;
    color: #333;
}

.time-input input {
    width: 25%;
    padding: 0.5rem;
    border: 1px solid #d1d8e0;
    border-radius: 8px;
    text-align: center;
    font-size: 1rem;
    color: #333;
    background-color: #f7f7f7;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.time-input input:focus {
    border-color: #1e3799;
    box-shadow: 0 0 5px rgba(30, 55, 153, 0.3);
}

/* Calculate Button */
.calculate-button {
    background-color: #38ada9;
    color: white;
    font-size: 1rem;
    padding: 0.8rem 2rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-top: 1rem;
    width: 30%;
}

.calculate-button:hover {
    background-color: #0c2461;
    transform: translateY(-2px);
}

/* Result Display */
.result {
    margin-top: 2rem;
    font-size: 1.1rem;
    color: #1e3799;
    font-weight: 600;
}

.result p {
    font-size: 1.3rem;
    font-weight: 700;
    color: #333;
    margin: 0.8rem 0;
}

/* Satisfaction Message */
.satisfaction {
    margin-top: 2.5rem;
    font-size: 0.9rem;
    color: #747d8c;
    font-style: italic;
}

/* Add hover effects for input fields */
input[type="number"] {
    transition: all 0.3s ease;
}

input[type="number"]:hover {
    background-color: #f0f8ff;
}

.star-rating {
    display: flex;
    gap: 8px;
    margin: 12px 0;
    justify-self: center;
  }
  

/* Responsive Design */
@media (max-width: 768px) {
    .time-input label {
        width: 120px;
    }
}

@media (max-width: 480px) {
    .calculator-container {
        padding: 1.5rem;
    }
  
    .time-input label {
        width: 100px;
    }
  
    h1 {
        font-size: 1.4rem;
    }

    h2 {
        font-size: 0.95rem;
    }

    .option-button, .calculate-button {
        width: 100%;
    }
}
